function floorplanFilters() {

    if ( !! $j('#home-filters').length ) {

        $j('#home-filters select').change(function(e){
            updateFilters();
        });

        var noresults = '0 Results';
        function updateFilters() {
            var filters = '';
            $j('#home-filters select').each(function(index){
                var el = $j(this);
                var val = el.val();
                if ( val != 'any' ) {
                    filters += '.'+el.val()+'';
                }
            });
            if ( $j('.fp-grid>div.fp-slide'+filters+'').length ) {
                var count = $j('.fp-grid>div.fp-slide'+filters+'').length;
                var plural = '';
                if ( count != 1) {  plural = 's'; }
                $j('.results-text').html(''+count+' Result'+plural+'');
                $j('.fp-grid>div.fp-slide:not('+filters+')').hide();
                $j('.fp-grid>div.fp-slide'+filters+'').fadeIn(300);
                $j('.fp-table>tbody>tr:not('+filters+')').addClass('hiddenRow');
                $j('.fp-table>tbody>tr'+filters+'').removeClass('hiddenRow');
                $j('.no-results').hide();
            } else {
                $j('.fp-grid>div.fp-slide:not('+filters+')').hide();
                $j('.fp-table>tbody>tr:not('+filters+')').addClass('hiddenRow');
                $j('.results-text').html(noresults);
                $j('.no-results').fadeIn(300);
            }
            bLazy.revalidate();
        }

        $j(document).on('click', '.view-toggle>button', function(e){
            e.preventDefault();
            var el = $j(this);
            if ( $j('.fp-grid').hasClass('active') ) {
                el.html('Grid View <i class="fal fa-th-large"></i>');
                $j('.fp-grid').removeClass('active');
                $j('.fp-table').addClass('active');
            } else {
                el.html('List View <i class="fal fa-list"></i>');
                $j('.fp-grid').addClass('active');
                $j('.fp-table').removeClass('active');
            }
        });

        $j('.btn-reset-filters').click(function(){
            $j('#home-filters').trigger('reset');
            updateFilters();
        });

        $j(document).on('click', '.btn-toggle-filters', function(e){
            e.preventDefault();
            var _hf = $j('#home-filters');
            var el = $j(this);
            if ( ! _hf.hasClass('active') ) {
                _hf.slideDown(300).addClass('active');
            } else {
                _hf.slideUp(300).removeClass('active');
            }
        });

        return;

        // debug
        //$j('.view-toggle>button').click();
        setTimeout(function(){
            $j('#garage-filter').val('garage-3');
            $j('#bathrooms-filter').val('bathrooms-2');
            updateFilters();
        },500);

    }

}
