function animations() {

    $j( '.ss.preload' ).removeClass('preload');
    $j( '.akel-news-top.preload' ).removeClass('preload');

	inView('.akel-design-studio').on('enter', introUnleash);
	inView('.akel-design-studio').on('exit', outroUnleash);

	inView('.about-img.ramsey').on('enter', imgfadein2);
	inView('.about-img.ramsey').on('exit', imgfadeout2);
	function imgfadein2() {
		setTimeout(function(){
			$j('.about-img.ramsey').removeClass('start');
		}, 100);
	}
	function imgfadeout2() {
		$j('.about-img.ramsey').addClass('start');
	}
	inView('.about-img:not(.ramsey)').on('enter', imgfadein);
	inView('.about-img:not(.ramsey)').on('exit', imgfadeout);
	function imgfadein() {
		setTimeout(function(){
			$j('.about-img:not(.ramsey)').removeClass('start');
		}, 100);
	}
	function imgfadeout() {
		$j('.about-img:not(.ramsey)').addClass('start');
	}

	var unleash;
	function introUnleash() {
		$j('.akel-design-studio li').each(function(index){
			var el = $j(this);
			setTimeout(function(){
				el.removeClass('start');
			}, index * 200);
			if (index == 3 ) {
				clearTimeout(unleash);
				unleash = setTimeout(function(){
					$j('.accent-tiles').removeClass('start');
				}, index * 250);
			}
		});
	}
	function outroUnleash() {
		$j('.akel-design-studio li').each(function(index){
			var el = $j(this);
			setTimeout(function(){
				el.addClass('start');
			}, index * 20);
			if (index == 3 ) {
				$j('.accent-tiles').addClass('start');
			}
		});
	}
	$j('.akel-design-studio li:nth-child(4) a').click(function(){
		$j('.akel-design-studio').addClass('ads-active');
	});

	document.body.onmousedown = function() {
		if ( $j('.akel-design-studio').hasClass('ads-hover') ) {
			$j('.akel-design-studio').addClass('ads-active');
		}
	}

	$j('.akel-design-studio li:nth-child(4)').hover(function(){
		$j('.akel-design-studio').addClass('ads-hover');
	},function(){
		$j('.akel-design-studio').removeClass('ads-active ads-hover');
	});

    if (jQuery().hoverdir) {
        jQuery('.akel-feature>div').each(function() {
            jQuery(this).hoverdir();
        });
        jQuery('.akel-design-studio li').hoverdir();
        jQuery('.myakel-actions li b').hoverdir();
        jQuery('.akel-form-contact-lg').hoverdir();
        jQuery('.stats-block li').hoverdir();
        jQuery('.myakel-card .headshot').hoverdir();
        jQuery('.myakel-card .content').hoverdir();
        jQuery('.top-logos a').hoverdir();
        jQuery('.gallery-row div').hoverdir();
    }

	inView('.stats-block').on('enter', stats);
	inView('.stats-block').on('exit', statsReset);
};
