function akelLogos() {
	var anims = [
		{el:'akel-homes-logo',json:'akelhomes',path:'a/akel-homes.json'},
		{el:'sundance-logo',json:'sundance',path:'a/sundance.json'},
		{el:'sundance-logo2',json:'sundance2',path:'a/sundance2.json'},
		{el:'sundance-a',json:'sundance-a',path:'a/sundance-a.json'},
		{el:'sundance-a2',json:'sundance-a2',path:'a/sundance-a2.json'},
		{el:'akel-logo-top',json:'akelhomes-top',path:'a/akel-homes.json'},
		{el:'akel-homes-logo-footer',json:'akelhomesf',path:'a/akel-homes.json'},
		{el:'akel-homes-logo-footer-sm',json:'akelhomesfsm',path:'a/akel-homes.json'},
		{el:'akel-homes-logo-header',json:'akelhomesh',path:'a/akel-homes.json'},
		{el:'myakel-logo',json:'myakel',path:'a/myakel.json'},
		{el:'myakel-logo-footer',json:'myakelf',path:'a/myakel.json'},
		{el:'akel-logo',json:'akel',path:'a/akel.json'},
		{el:'akel2-logo',json:'akel2',path:'a/akel2.json'},
		{el:'akel-a',json:'a1',path:'a/akel-a2.json',autoplay:true},
		{el:'a2-logo',json:'a2',path:'a/akel-a2.json'},
		{el:'welcomehome-logo',json:'welcomehome',path:'a/welcome-home.json',autoplay:true},
		{el:'dbl-logo',json:'dbl',path:'a/dream-build-love.json'},
		{el:'ds-logo',json:'ds',path:'a/design-studio.json'},
		{el:'dss-logo',json:'dss',path:'a/design-studio-square.json'},
		{el:'dss-logo2',json:'dss2',path:'a/design-studio-square.json'},
		{el:'solana-logo',json:'solana',path:'a/solana.json'},
		{el:'solanawelcome',json:'solanawelcome',path:'a/solana.json'},
		{el:'solana-logo-footer',json:'solanaf',path:'a/solana.json'},
		{el:'solana-logo-header',json:'solanah',path:'a/solana.json'},
		{el:'solana1-logo',json:'solana1',path:'a/solana-icon-hover.json'},
		{el:'solana2-logo',json:'solana2',path:'a/solana-icon-intro.json'},
		{el:'solana3-logo',json:'solana3',path:'a/solana-icon-outro.json'},
		{el:'palms-logo',json:'palms',path:'a/catalina-palms.json'},
		{el:'palms-logo-gold',json:'palms2',path:'a/catalina-palms-gold.json'},
		{el:'mail-icon',json:'mailicon',path:'a/email-hover.json'},
		{el:'mail-icon2',json:'mailicon2',path:'a/email-sent.json'},
		{el:'tagline-intro',json:'tagline-intro',path:'svg/build-a-life-you-love-intro-light.json'},
		{el:'tagline-top',json:'tagline-top',path:'a/build/build-a-life-you-love-intro.json'},
		{el:'logo-anim-tagline',json:'tagline',path:'svg/build-a-life-you-love-hover.json'},
		{el:'catalina-palms-header',json:'cpheader',path:'a/catalina-palms/catalina-palms-logo-animation-hover.json'},
		{el:'catalina-palms-footer',json:'cpfooter',path:'a/catalina-palms/catalina-palms-logo-animation-hover.json'},
		{el:'cp1',json:'cp1',path:'a/catalina-palms/catalina-palms-logo-animation-stacked-intro.json'},
		{el:'cp2',json:'cp2',path:'a/catalina-palms/catalina-palms-logo-animation-stacked-hover.json'},
		{el:'cp3',json:'cp3',path:'a/catalina-palms/catalina-palms-logo-animation-intro-web.json'},
		{el:'cp4',json:'cp4',path:'a/catalina-palms/catalina-palms-logo-animation-intro-web.json'},
		{el:'cp5',json:'cp5',path:'a/catalina-palms/catalina-palms-logo-animation-logo-hover-web.json'},
		{el:'cp6',json:'cp6',path:'a/catalina-palms/catalina-palms-logo-animation-logo-intro-web.json'},
		{el:'sd1',json:'sd1',path:'a/sundance/sundance-stacked-flying-loop.json'},
		{el:'sd4',json:'sd4',path:'a/sundance/sundance-logo-animation-stacked-bird-hover.json'},
		{el:'sd5',json:'sd5',path:'a/sundance/sundance-stacked-flying-hover-980.json',autoplay:true,loop:true},
		{el:'sd6',json:'sd6',path:'a/sundance/sundance-horizontal-flying-hover-980.json',autoplay:true,loop:true},
		{el:'sd7',json:'sd7',path:'a/sundance/sundance-logo-animation-stacked-hover.json'},
		{el:'sd8',json:'sd8',path:'a/sd/v2/sundance-logo-horizontal-twirl-web.json'},
		{el:'sd9',json:'sd9',path:'a/sd/v3/sd4.json'},
		{el:'sd10',json:'sd10',path:'a/sd/v2/sundance-logo-horizontal-twirl-web.json'},
		{el:'sd11',json:'sd11',path:'a/sd/sundance-logo-horizontal-twirl-web-notag.json'},
		{el:'sd12',json:'sd12',path:'a/sd/sundance-logo-logo-web.json'},
		{el:'sd13',json:'sd13',path:'a/sd/v4/v1.json'},
		{el:'sundance-intro',json:'sundanceintro',path:'a/sundance/sundance-logo-animation-stacked-bird-hover.json'},
		{el:'build',json:'build',path:'a/build/build-a-life-you-love-intro.json'},
		{el:'build-footer',json:'buildfooter',path:'a/build/build-a-life-you-love-intro.json'},
		{el:'buildig',json:'buildig',path:'a/build/build-a-life-you-love-intro.json'}
	]

	var logoMap = {}

	anims.forEach(cfg=>{
		var node = document.getElementById(cfg.el)
		if(!node) return
		var anim = bodymovin.loadAnimation({
			container: node,
			path: window.location.origin+'/wp-content/themes/akel/assets/img/'+cfg.path,
			renderer: 'svg',
			loop: cfg.loop||false,
			autoplay: cfg.autoplay||false
		})
		logoMap[cfg.json] = anim
	})

	$j('a,button,[tabindex="0"]').on('focus',function(){
		var el = $j(this).find('.animate')
		if(el.length) el.trigger('mouseenter')
	})

	$j(document).on('mouseenter click','.animate',function(){
		var el = $j(this)
		var src = el.data('json')
		if($j(window).width()<1024 && src!='tagline') return
		el.removeClass('preload')
		var anim = logoMap[src]
		if(anim){
			anim.stop()
			anim.play()
		}
	})

	$j('.email-icon .animate.preload').removeClass('preload')

	var mailanimate = ''
	$j('.fs-main').mouseenter(function(){
		clearTimeout(mailanimate)
		mailanimate = setTimeout(function(){
			$j('.email-icon').removeClass('success')
			if(logoMap.mailicon){
				logoMap.mailicon.stop()
				logoMap.mailicon.play()
			}
		},100)
	})

	$j('form.subscribe button').click(function(){
		clearTimeout(mailanimate)
		$j('.email-icon').addClass('success')
		if(logoMap.mailicon2){
			logoMap.mailicon2.stop()
			logoMap.mailicon2.play()
		}
	})

	setTimeout(function(){
		['tagline-intro','build','buildig','sundanceintro','dbl','welcomehome'].forEach(k=>{
			if(logoMap[k]){
				logoMap[k].play()
			}
		})
	},500)

	inView('.dbl.akel-feature').on('enter',function(){
		['dbl','cp3','welcomehome'].forEach(k=>{
			if(logoMap[k]){
				logoMap[k].stop()
				logoMap[k].play()
			}
		})
	})

	inView('.igfeed').on('enter',function(){
		if(logoMap.buildig){
			logoMap.buildig.stop()
			logoMap.buildig.play()
		}
	})

	inView('.animate.sd').on('enter',function(){
		if(logoMap.sundanceintro){
			logoMap.sundanceintro.stop()
			logoMap.sundanceintro.play()
		}
	})
}
