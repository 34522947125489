function updateTourHTML(selector) {
    var el = $j('.'+selector);
    var time = el.find('.tours-requested [name="appointment_time"]').val() || '10:00 AM';
    var date = el.find('.tours-requested [name="appointment_date"]').val();
    var type = el.find('.tours-requested [name="appointment_type"]').val() || 'In-Person';
    var community = el.data('community');
    // Use string template literals to inject values into HTML string
    var htmlString = `<p class="clear w100">
        <button class="btn-appointment-back btn btn-primary-outline btn-sm right"><i class="fas fa-chevron-left"></i> Go Back</button>
        <strong class="hide">Community:</strong> <strong>${community}</strong><br>
        <strong class="hide">Date:</strong> <strong>${date}</strong><br>
        <strong class="hide">Time:</strong> <strong>${time}</strong><br>
        <strong class="hide">Tour Type:</strong>
        <strong>${type}</strong><br></p>`;
    el.find('.tours-requested .selected-appointment-info').html(htmlString);
}
function handleSlideChange(direction, sliderInfo) {
    if (typeof window.onSlideCallback === 'function') {
        window.onSlideCallback(direction, sliderInfo);
    }
}
function initSlider(sliderName){
    var slider=$j('.'+sliderName);
    if( ! slider.find('.akel-booking-date.loading').length) {
        return;
    }
    slider.find('.akel-booking-date.loading').removeClass('loading');
    slider.find('[name="appointment_time"]').val('10:00 AM');
    slider.find('.akel-booking-type.calendy a').click(function(event){
        event.preventDefault();
        var el = $j(this);
        var url = slider.find('.akel-booking-type').data('url');
        var type = el.data('booking-type');
        var date = slider.find('.akel-booking-date a.active').data('date');
        var month = slider.find('.akel-booking-date a.active').data('month');
        var url = url+'?month='+month+'&a2='+type+'&a3=1&date='+date;
        //document.location.href=( url );
        window.open(url);
    });

    var sliderInstance=tns({
        container:slider.find('.akel-booking-date-slider')[0],
        items:1,
        mouseDrag:true,
        swipeAngle:false,
        loop:false,
        responsive:{'320':{items:2},'600':{items:3}}
    });
    sliderInstance.events.on('transitionEnd',function(){
        let info=sliderInstance.getInfo();
        let direction=info.indexCached<info.index?'next':'prev';
        handleSlideChange(direction,info);
        slider.find('.tns-item a.active').removeClass('active');
        if(direction=='next'){
            slider.find('.tns-slide-active').first().next().next().find('a').trigger('click');
        } else {
            slider.find('.tns-slide-active').first().find('a').trigger('click');
        }
    });
    slider.find('.tns-controls button').click(function(e){
        e.preventDefault();
        var direction=$j(this).data('controls');
        slider.find('.tns-item a.active').removeClass('active');
        if(direction=='next'){
            slider.find('.tns-slide-active').first().next().next().find('a').trigger('click');
        } else {
            slider.find('.tns-slide-active').first().find('a').trigger('click');
        }
    });
    slider.find('div.tns-item>a').click(function(event){
        event.preventDefault();
        var date=$j(this).data('date');
        slider.find('a.active').removeClass('active');
        $j(this).addClass('active');
        slider.find('[name="appointment_date"]').val(date);
        updateTourHTML(sliderName);
        var url=$j('.akel-booking-type.calendy').data('url');
        var month=slider.find('a.active').data('month');
        var virtualurl=url+'?month='+month+'&a2=2&a3=1&date='+date;
        var personurl=url+'?month='+month+'&a2=1&a3=1&date='+date;
        $j('.akel-booking-type.calendy a[data-booking-type="2"]').attr('href',virtualurl);
        $j('.akel-booking-type.calendy a[data-booking-type="1"]').attr('href',personurl);
    });
    slider.find('div.tns-item a.start').trigger('click').removeClass('start');
    slider.find('.akel-booking-type button').click(function(event){
        event.preventDefault();
        var type=$j(this).data('booking-type');
        slider.find('.akel-booking-type button').removeClass('active');
        $j(this).addClass('active');
        slider.find('[name="appointment_type"]').val(type);
        if (slider.find('.tours-requested.step-2').hasClass('active')){
            slider.find('.tours-requested.step-1').slideDown(750,'easeOutCirc');
            slider.find('div.text.step-1').slideDown(750,'easeOutCirc');
            slider.find('.tours-requested.step-2').slideUp(750,'easeOutCirc').removeClass('active');
        } else {
            slider.find('.tours-requested.step-1').slideUp(750,'easeOutCirc');
            slider.find('div.text.step-1').slideUp(750,'easeOutCirc');
            slider.find('.tours-requested.step-2').slideDown(750,'easeOutCirc').addClass('active');
        }
        updateTourHTML(sliderName);
    });
    $j(document).on('click', '.'+sliderName+' .tours-requested .btn-appointment-back',function(){
        slider.find('.akel-booking-type button.active').click();
    });
    slider.find('.time-option').click(function(){
        var text=$j(this).text();
        slider.find('[name="appointment_time"]').val(text);
        updateTourHTML(sliderName);
        if(!$j(this).hasClass('active')){
            slider.find('.time-option').removeClass('active');
            $j(this).addClass('active');
        }else{
            return;
            $j(this).removeClass('active');
        }
    });
}
