function toolbar() {
    $j('.tags .btn').on('click keydown', function(e) {
        $j('.cats.open').removeClass('open');
        $j('.cats.hover-open').removeClass('hover-open');
        if (e.type === 'click' || e.key === 'Enter') {
            e.preventDefault()
            const $parent = $j(this).parent()
            const isHover = $parent.hasClass('hover-open')
            $parent.removeClass('hover-open')
            const isExpanded = $parent.hasClass('open')
            if (!isExpanded || isHover) {
                $j('.tags .open').not($parent).removeClass('open hover-open')
                    .find('button').attr('aria-expanded', 'false')
                    .next('.tags-list').attr('aria-hidden', 'true').addClass('hidden')
                $j('.toolbar .cats .open').removeClass('open hover-open')
                    .find('button').attr('aria-expanded', 'false')
                    .next('ul').attr('aria-hidden', 'true').addClass('hidden')
            }
            $parent.toggleClass('open')
            const nowExpanded = $parent.hasClass('open')
            $j(this).attr('aria-expanded', nowExpanded)
                .next('.tags-list').attr({
                    'aria-hidden': !nowExpanded,
                    'role': 'menu',
                    'aria-label': 'Tags menu'
                })[nowExpanded ? 'removeClass' : 'addClass']('hidden')
                .find('a').attr('role', 'menuitem')
        }
    })

    $j('.toolbar .cats button').on('click keydown', function(e) {
        $j('.tags.open').removeClass('open');
        $j('.tags.hover-open').removeClass('hover-open');
        if (e.type === 'click' || e.key === 'Enter') {
            e.preventDefault()
            const $parent = $j(this).parent()
            const isHover = $parent.hasClass('hover-open')
            $parent.removeClass('hover-open')
            const isExpanded = $parent.hasClass('open')
            if (!isExpanded || isHover) {
                $j('.toolbar .cats .open').not($parent).removeClass('open hover-open')
                    .find('button').attr('aria-expanded', 'false')
                    .next('ul').attr('aria-hidden', 'true').addClass('hidden')
                $j('.tags .open').removeClass('open hover-open')
                    .find('button').attr('aria-expanded', 'false')
                    .next('.tags-list').attr('aria-hidden', 'true').addClass('hidden')
            }
            $parent.toggleClass('open')
            const nowExpanded = $parent.hasClass('open')
            $j(this).attr('aria-expanded', nowExpanded)
                .next('ul').attr({
                    'aria-hidden': !nowExpanded,
                    'role': 'menu',
                    'aria-label': 'Category menu'
                })[nowExpanded ? 'removeClass' : 'addClass']('hidden')
                .find('li a').attr('role', 'menuitem')
        }
    })

    $j(document).on('keyup', function(e) {
        if (e.key === 'Escape') {
            $j('.tags .open, .toolbar .cats .open').each(function() {
                const $el = $j(this)
                $el.removeClass('open hover-open')
                const $btn = $el.find('button')
                const $list = $btn.next()
                $btn.attr('aria-expanded', 'false').focus()
                $list.attr('aria-hidden', 'true').addClass('hidden')
            })
        }
    })

    $j('.tags-list, .toolbar .cats ul').on('focusout', function(e) {
        const $list = $j(this)
        if (!$list.has(e.relatedTarget).length) {
            const $btn = $list.prev('button')
            $btn.attr('aria-expanded', 'false')
            $list.attr('aria-hidden', 'true').addClass('hidden')
            $btn.parent().removeClass('open hover-open')
        }
    })

    $j('.tags').on('mouseenter', function() {
        $j('.toolbar .cats .open').removeClass('open hover-open')
            .find('button').attr('aria-expanded', 'false')
            .next('ul').attr('aria-hidden', 'true').addClass('hidden')
        const $wrap = $j(this)
        const $btn = $wrap.find('button')
        const $list = $btn.next()
        $wrap.addClass('open hover-open')
        $btn.attr('aria-expanded', 'true')
        $list.attr({
            'aria-hidden': 'false',
            'role': 'menu'
        }).removeClass('hidden')
        $list.find('a, li a').attr('role', 'menuitem')
    })

    $j('.toolbar .cats').on('mouseenter', function() {
        $j('.tags .open').removeClass('open hover-open')
            .find('button').attr('aria-expanded', 'false')
            .next('.tags-list').attr('aria-hidden', 'true').addClass('hidden')
        const $wrap = $j(this)
        const $btn = $wrap.find('button')
        const $list = $btn.next()
        $wrap.addClass('open hover-open')
        $btn.attr('aria-expanded', 'true')
        $list.attr({
            'aria-hidden': 'false',
            'role': 'menu'
        }).removeClass('hidden')
        $list.find('a, li a').attr('role', 'menuitem')
    })

    $j('.tags, .toolbar .cats').on('mouseleave', function() {
        const $wrap = $j(this)
        const $btn = $wrap.find('button')
        const $list = $btn.next()
        if (!$btn.is(':focus') && !$list.is(':focus')) {
            $btn.attr('aria-expanded', 'false')
            $list.attr('aria-hidden', 'true').addClass('hidden')
            $wrap.removeClass('open hover-open')
        }
    })
}
