function mobile() {
    // TOGGLE MOBILE NAV

    $j('button#oc').click(function() {
        var el = $j(this);
        if ( ! $j('body.oc-open').length ) {
            $j('#nav, #site-overlay').fadeIn(200);
            $j('body').addClass('oc-open');
            $j('body.oc-akel-open').removeClass('oc-akel-open');
        } else {
            $j('#nav, #site-overlay').fadeOut(200);
            $j('body').removeClass('oc-open');
			$j('.akel-phone>li.open').removeClass('open');
			$j('#nav .phone-nav span').html('Call Now');
			$j('#nav .akel-phone li>a>span').html('Call Now');
        }
    });
    var logoTimeouts = []
    $j('.hamburger').click(function() {
        var el = $j(this)
        el.toggleClass('is-active')
        logoTimeouts.forEach(clearTimeout)
        logoTimeouts = []
        if (!$j('body.oc-akel-open').length) {
            $j('#nav, #site-overlay').fadeIn(200)
            $j('body').addClass('oc-akel-open')
            $j('body.oc-open').removeClass('oc-open')
            var initialTimeout = setTimeout(function() {
                $j('.mobile-nav-logo.preload').each(function(index) {
                    var $this = $j(this)
                    var removePreloadTimeout = setTimeout(function() {
                        $this.removeClass('preload')
                    }, index * 100)
                    logoTimeouts.push(removePreloadTimeout)
                })
            }, 150)
            logoTimeouts.push(initialTimeout)
        } else {
            $j('#nav, #site-overlay').fadeOut(200)
            $j('body').removeClass('oc-akel-open')
            $j('.akel-phone>li.open').removeClass('open')
            $j('#akel-nav .phone-nav span').html('Call Now')

            var resetTimeout = setTimeout(function() {
                $j('.mobile-nav-logo').addClass('preload')
            }, 300)
            logoTimeouts.push(resetTimeout)
        }
    })

    $j('#site-overlay').click(function() {
        if ($j('body.oc-akel-open').length) {
            $j('.hamburger').click();
        } else {
            $j('button#oc').click();
        }
    });
	//return;
	setTimeout(function() {
		//$j('button#oc').click();
		//$j('.hamburger').click();
	}, 500);
    var sizer = $j('#msizer').width();
    if ( sizer < 21 ) {
    	setTimeout(function() {
    		if ( $j('#akel-nav').hasClass('preload') ) {
    			$j('#akel-nav li.communities').addClass('open');
    		    $j('#akel-nav li.communities>a').find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
    	    	$j('#akel-nav li.communities a').next('ul').slideDown(10);
    			$j('#akel-nav .mobile-accent-frame').addClass('inview');
    		}
    		if (
    			! $j('#nav li.current_page_parent').length
    			&& ! $j('#nav li.current-menu-item').length
    			&& ! $j('body.community').length ) {
    			$j('#nav').addClass('preload com-open slide');
    		    $j('#nav li.communities>a').find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
    		    $j('#nav li.communities').addClass('open');
    			$j('#nav .mobile-accent-frame').addClass('inview');
    		}
    		$j('#oc-btn').click();
    	}, 300);
    }


    $j(document).on('keydown', function(e) {
        if (e.key == "Escape") {
            $j('a:focus').blur();
            if ( $j('#oc-akel-btn.is-active').length ) {
                $j('#oc-akel-btn.is-active').click();
                return;
            }
            if ( $j('body.oc-open').length ) {
                $j('#oc').click();
                return;
            }
            $j('li.open').find('span.fa-caret-up').addClass('fa-caret-down').removeClass('fa-caret-up');
            $j('li.open').removeClass('open');
            $j('li.focus').removeClass('focus');
            $j('.hero .cover').stop(true, false).fadeTo(180,.4);
        }
    });
	return
	if ( $j('body').hasClass('community') ) {
		$j('#akel-nav').addClass('com-open slide');
	    $j('#akel-nav li.communities>a').find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
	    $j('#akel-nav li.communities').addClass('open');
	    $j('#akel-nav li.communities a').next('ul').slideDown(10);
		$j('#akel-nav .mobile-accent-frame').addClass('inview');
	}
	if ( ! $j('#nav li.current_page_parent').length && ! $j('#nav li.current-menu-item').length && ! $j('body.community').length ) {
		//$j('#nav li.communities>a').click();
		$j('#nav').addClass('com-open slide');
	    $j('#nav li.communities>a').find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
	    $j('#nav li.communities').addClass('open');
	    $j('#nav li.communities a').next('ul').slideDown(10);
		$j('#nav .mobile-accent-frame').addClass('inview');
	}
	return;
}
