function scrollProgress() {
    if ( ! $j('div.scroll-progress').length ) {
        return;
    }
    //GET VARIABLES
    $j(window).on('ready load resizeEnd', function() {
        var $articleContent = $j('div.scroll-progress'),
                panelHeight = 90,
                articleOffset = $articleContent.offset();

        window.headerHeight = $j('#header').height();
        window.articleHeight = $articleContent.height();
        window.windowHeight = $j(window).height();
        window.articleOffsetTop = articleOffset.top - panelHeight;
    });
    //ON SCROLL
    $j(window).on('scroll ready load resize resizeEnd', function(event) {
        var scrollTop = $j(window).scrollTop();
        var scrollProgress = ((scrollTop - window.articleOffsetTop) / (window.articleHeight - window.windowHeight / 6));
        //log('scrolled', scrollTop, scrollProgress);
        // HEADER ANIMATION
		var scrollAmount = 100 - Math.round(100 * scrollProgress);
        if (scrollProgress >= 0 && scrollProgress <= 1) {
            $j('.progress-bar').addClass('active');
			$j('.progress-cover').css('width', scrollAmount + "%");
            $j('.progress-container').addClass('active');
        } else if (scrollProgress > 1) {
            $j('.progress-bar').removeClass('active');
			$j('.progress-cover').css('width', "0%");
            $j('.progress-container').removeClass('active');
        } else {
            $j('.progress-bar').removeClass('active');
			$j('.progress-cover').css('width', "100%");
            $j('.progress-container').removeClass('active');
        }
    });
}



var scrollerfn = null;
var _sps = 50;
var scrolledtop = 1;
var prevScrollpos = window.pageYOffset;

$j(window).on('scroll', function() {
    clearTimeout(scrollerfn);
    scrollerfn = setTimeout(scroller, _sps);
});

/*
jQuery(window).bind('scroll', function() {
	if ($(window).scrollTop() > 130) {
		//$('#header').addClass('fixed');
        $('#oc').stop(false,false).animate({'top':"0px"}, {duration:600, easing:'easeOutCirc'});
		$('#oc').removeClass('hidden');
	}	else {
		//$('#header').removeClass('fixed');
        $('#oc').stop(false,false).animate({'top':"-80px"}, {duration:400, easing:'easeOutCirc'});
		$('#oc').addClass('hidden');
	}
});
*/

function fpScroller() {
    if ( $j('body.scrolling').length ) {
        return;
    }
    $j('section.view-region.inview').removeClass('inview');
    var inview = $j('section.view-region:in-viewport:first').find('.anchor>a').attr('id');
    if ( inview == 'overview' ) {
        $j('.fp-header-action ul.cycle-slideshow').cycle('resume');
    } else {
        $j('.fp-header-action ul.cycle-slideshow.cycle-running').cycle('pause');
    }
    $j('section.view-region:in-viewport:first').addClass('inview');
    $j('.sub-nav li.active').removeClass('active');
    $j('ul.nav a[href="#'+inview+'"]').parent().addClass('active');
    $j('.sub-nav .current>span').html($j('ul.nav a[href="#'+inview+'"]').html());
}

function scroller() {
    if ( $j('body.show-top').length ) {
        return;
    }
    var scrolltop = $j(window).scrollTop();
    var currentScrollPos = window.pageYOffset;
    if ( $j('body.single-floorplan').length) {
        fpScroller();
    }
    if (scrolltop > scrolledtop && prevScrollpos < currentScrollPos) {
        if ( ! $j('body.oc-open').length && ! $j('body.oc-akel-open').length ) {
            //$j('body').addClass('scrolled');
            $j('#back-to-akel').addClass('scrolled');
        }
    } else {
        //$j('body:not(.single-floorplan)').removeClass('scrolled');
        $j('#back-to-akel').removeClass('scrolled');
    }
    if (scrolltop > scrolledtop ) {
        //$j('.scrolltop').addClass('show');
        //$j('.scrolltop:hidden').stop(true, true).fadeIn();
        $j('body').addClass('started');
    } else {
        //$j('.scrolltop').stop(true, true).fadeOut();
        //$j('.scrolltop').removeClass('show');
        $j('body').removeClass('started');
        $j('body').removeClass('scrolled');
    }
    if ( $j('div.view-region').length) {
        navScroller();
    }
    prevScrollpos = currentScrollPos;
    if ( !! $j('#solana-bay-map').length ) {
        var heights = window.innerHeight;
        document.getElementById('solana-bay-map').style.height = heights - 82 + "px";
    }

    if ( !! $j('.hero video').length ) {
        if ( $j('.hero .cover:in-viewport').length ) {
            $j('.hero-controls .play:not(.stopped)').click();
            log('play');
        } else if ( $j('.hero .cover').length ) {
            $j('.hero-controls .pause').click();
            log('pause');
        }
    }

    if ( !! $j('.videos-wrap').length ) {
        activeVideo();
    }
}

$j(window).on('resize resizeEnd', function(event) {
	// reset flying focus
    $j('#flying-focus').css({'top': 0, 'left': 0, 'right' : 0, 'bottom' : 0});
	var sizer = $j('#msizer').width();
	if ( sizer >= 30 && $j('body.oc-open').length || sizer >= 30 && $j('body.oc-akel-open').length ) {
		// Close mobile menu
        $j('li.menu-item-has-children.open').find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
        $j('li.menu-item-has-children.open ul.sub-menu').removeAttr('style');
		$j('li.menu-item-has-children.open').removeClass('open');
		$j('#nav, #akel-nav').removeClass('com-open slide preload');
		$j('#site-overlay').hide();
		$j('#oc-akel-btn').removeClass('is-active');
		$j('body').removeClass('oc-akel-open oc-open');
		$j('.phone-nav, .akel-phone>li.open').find('a>span').text('Call Now');
		$j('.phone-nav, .akel-phone>li.open').removeClass('open');
	}
});


function updateAction() {
    var available = $j('.header-main .inner').width() - $j('.header-main div.logo').width();
    var needed = $j('#oc').outerWidth() + $j('.tablet-actions').outerWidth(true);
    var hidecount = Math.ceil(((available - needed) * -1) / 50);
    if (hidecount > 0) {
        $j('.tablet-actions .nav-sizer:not(.hide-nav)').reverse().each(function(index) {
            if (index < hidecount) {
                $j(this).addClass('hide-nav');
            }
        });
    } else {
        $j('.tablet-actions .nav-sizer.hide-nav').slice(0, hidecount * -1).removeClass('hide-nav');
    }
}

jQuery.fn.reverse = [].reverse;
$j.fn.reverse = Array.prototype.reverse;

function updateNav() {
    var available = ($j('.header-main .inner').width() - $j('.header-main div.logo').width()) || 0;
    var nav = Math.ceil($j('#nav .nav').outerWidth()) || 0;
    var actions = Math.ceil($j('#nav .actions').outerWidth(true)) || 0;
    var needed = nav + actions;
    var spaceNeeded = needed - available;

    if (!$j('#nav .menu .more-menu').length) {
        $j('#nav .menu').append('\
            <li class="more-menu menu-item-has-children hide-nav show-lg"><a href="#">\
            <span class="link">More<span class="dd far fa-plus hide-lg"></span><span class="dd fa show-lg fa-caret-down"></span></span>\
            </a><ul class="sub-menu"></ul></li>');
    }

    var $moreMenu = $j('#nav .menu .more-menu');
    $moreMenu.find('.sub-menu').empty();

    if (spaceNeeded > 0) {
        // Priority 1: Regular menu items
        $j('#nav .menu > li:not(.menu-item-has-children, .more-menu)').reverse().each(function() {
            var $item = $j(this);
            if (!$item.hasClass('in-more-menu')) {
                var $clone = $item.clone(true);
                $moreMenu.find('.sub-menu').prepend($clone);
                $moreMenu.find('.sub-menu li:first-child').find('span').append('<span class="ddarrow far fa-chevron-right"></span>');
                $item.addClass('hide-nav in-more-menu');

                needed = Math.ceil($j('#nav .nav').outerWidth()) + Math.ceil($j('#nav .actions').outerWidth(true));
                if (needed <= available) return false;
            }
        });

        // Priority 2: Action icons
        if (needed > available) {
            $j('#nav .actions .nav-icon:not(.hide-nav)').each(function() {
                var $icon = $j(this);
                if (!$icon.hasClass('in-more-menu')) {
                    var link = $icon.find('a').attr('href') || '#';
                    var text = $icon.find('a span').text();
                    var $menuItem = $j('<li><a href="'+link+'"><span class="link">'+text+' <span class="ddarrow far fa-chevron-right"></span></span></a></li>');
                    $moreMenu.find('.sub-menu').append($menuItem);
                    $icon.addClass('hide-nav in-more-menu');

                    needed = Math.ceil($j('#nav .nav').outerWidth()) + Math.ceil($j('#nav .actions').outerWidth(true));
                    if (needed <= available) return false;
                }
            });
        }

        // Priority 3: Items with submenus - flatten hierarchy
        if (needed > available) {
            $j('#nav .menu > li.menu-item-has-children:not(.more-menu)').reverse().each(function() {
                var $item = $j(this);
                if (!$item.hasClass('in-more-menu')) {
                    // First add parent link
                    var $parentLink = $j('<li>').append($item.find('> a').clone());
                    //$moreMenu.find('.sub-menu').prepend($parentLink);

                    // Then add all submenu items directly
                    $item.find('> ul.sub-menu > li').each(function() {
                        var $subItem = $j(this).clone();
                        $moreMenu.find('.sub-menu').append($subItem);
                    });

                    $item.addClass('hide-nav in-more-menu');

                    needed = Math.ceil($j('#nav .nav').outerWidth()) + Math.ceil($j('#nav .actions').outerWidth(true));
                    if (needed <= available) return false;
                }
            });
        }
    } else {
        // Start by removing in-more-menu class from everything
        $j('#nav .in-more-menu').removeClass('in-more-menu');

        // Restore all hidden elements
        $j('#nav .hide-nav').not('.more-menu').removeClass('hide-nav');

        // Recheck if we need to hide anything
        needed = Math.ceil($j('#nav .nav').outerWidth()) + Math.ceil($j('#nav .actions').outerWidth(true));
        if (needed > available) {
            updateNav();
            return;
        }
    }

    if ($moreMenu.find('.sub-menu > li').length === 0) {
        $moreMenu.addClass('hide-nav');
        $j('#nav .in-more-menu').removeClass('in-more-menu');
    } else {
        $moreMenu.removeClass('hide-nav');
    }
}

var navloaded = 0;
function updateTopNav() {
    if ( ! $j('.top-nav nav').length ) {
        return;
    }
    var akela = $j('.akel-logo').width() || 0;
    var navoffset = 100;
    var elements = ($j('.top-logo').width() + akela + navoffset + $j('.top-tagline').width()) || 0;
    var available = $j('.header-top > .inner').width() - (elements) ;
    var needed = Math.ceil($j('.top-nav nav').outerWidth(true)) || 0;
    var spaceNeeded = needed - available;
    var nav = $j('.top-nav');
    if (! nav.find('ul .more-menu').length) {
        nav.find('nav > div > ul').append('\
            <li class="more-menu right-menu menu-item-has-children hide-nav show-lg"><a href="#">\
            <span class="link">More<span class="dd far fa-plus hide-lg"></span><span class="dd fa show-lg fa-caret-down"></span></span>\
            </a><ul class="sub-menu"></ul></li>');
    }

    var $moreMenu =  nav.find('ul .more-menu');
    $moreMenu.find('.sub-menu').empty();

    if (spaceNeeded > 0) {
        // Priority 1: Regular menu items
       nav.find('nav > div > ul > li:not(.menu-item-has-children, .more-menu)').reverse().each(function() {
            var $item = $j(this);
            if (!$item.hasClass('in-more-menu')) {
                var $clone = $item.clone(true);
                $moreMenu.find('.sub-menu').prepend($clone);
                $moreMenu.find('.sub-menu li:first-child').find('span').append('<span class="ddarrow far fa-chevron-right"></span>');
                $item.addClass('hide-nav in-more-menu');

                needed = Math.ceil(nav.find('nav').outerWidth(true));
                if (needed <= available) return false;
            }
        });

        // Priority 3: Items with submenus - flatten hierarchy
        if (needed > available) {
            nav.find('nav > div > ul > li.menu-item-has-children:not(.more-menu)').reverse().each(function() {
                var $item = $j(this);
                if (!$item.hasClass('in-more-menu')) {
                    // First add parent link
                    var $parentLink = $j('<li>').append($item.find('> a').clone());
                    //$moreMenu.find('.sub-menu').prepend($parentLink);

                    // Then add all submenu items directly
                    $item.find('> ul.sub-menu > li').each(function() {
                        var $subItem = $j(this).clone();
                        $moreMenu.find('.sub-menu').append($subItem);
                    });

                    $item.addClass('hide-nav in-more-menu');

                    needed = Math.ceil(nav.find('nav').outerWidth(true));
                    if (needed <= available) return false;
                }
            });
        }
    } else {
        $j('.top-nav').addClass('loaded');
        // Start by removing in-more-menu class from everything
        nav.find('.in-more-menu').removeClass('in-more-menu');

        // Restore all hidden elements
        nav.find('.hide-nav').not('.more-menu').removeClass('hide-nav');

        // Recheck if we need to hide anything
        needed = Math.ceil(nav.find('nav').outerWidth(true));
        if (needed > available) {
            updateTopNav();
            return;
        }
    }

    if ($moreMenu.find('.sub-menu > li').length === 0) {
        $moreMenu.addClass('hide-nav');
        nav.find('.in-more-menu').removeClass('in-more-menu');
    } else {
        $moreMenu.removeClass('hide-nav');
    }


    if ( !$j('.top-nav').hasClass('loaded') ) {
        clearTimeout(navloaded);
        navloaded = setTimeout(() => {
            $j('.top-nav').addClass('loaded');
        }, 200);
    }

}
var processNav = (function() {
    var frame;
    var frame2;
    return function() {
        if (frame2) cancelAnimationFrame(frame2);
        frame2 = requestAnimationFrame(updateTopNav);
        if (frame) cancelAnimationFrame(frame);
        frame = requestAnimationFrame(updateNav);
    };
})();

var resizing = 0;
$j(window).on('resize resizeEnd', function(event) {
	// reset flying focus
    //$j('#flying-focus').css({'top': 0, 'left': 0, 'right' : 0, 'bottom' : 0});
	var sizer = $j('#msizer').width();
    if ( sizer < 30) {
        updateAction();
    } else {
        $j('body').addClass('resizing');
        clearTimeout(resizing);
        resizing = setTimeout(() => {
            $j('body').removeClass('resizing');
        }, 500);
    }
});
const NavManager = (function($j) {
    function init(processNav) {
        function doubleProcess() {
            var sizer = $j('#msizer').width();
            if ( sizer < 30) {
                return;
            }
            processNav();
            setTimeout(processNav, 100);
        }
        $j(document).ready(doubleProcess);
        $j(window).on('load resize orientationchange hashchange focus blur', doubleProcess);
        $j(document).on('visibilitychange', function() {
            if (!document.hidden) {
                doubleProcess();
            }
        });
        if (window.ResizeObserver) {
            const resizeObserver = new ResizeObserver(doubleProcess);
            const body = document.querySelector('body');
            if (body) {
                resizeObserver.observe(body);
            }
        }
    }
    return { init };
})($j);
NavManager.init(processNav);
